import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { noop } from 'rxjs';

import { SecurityService } from '../data-access';

export const authGuard: CanActivateFn = () => {
  const urlParams = new URLSearchParams(window.location.search);  
  const coupon = urlParams.get('coupon') as string;
  if(coupon !== null){
    if(isJsonString(atob(coupon)))
      sessionStorage.setItem("couponDetails", coupon);
  }  

  function isJsonString(str: any) {
    console.log(str)
    try {
      JSON.parse(str);
    } 
    catch (e) {
      return false;
    }
    return true;
  }
  
  if (!inject(SecurityService).isAuthenticated()) {
    inject(Router).navigate(['/auth/sign-in']).then(noop);

    return false;
  }

  return true;
};
