import {
  Application,
  APPLICATION_ID,
  ApplicationFilter,
  ApplicationStatus,
  ApplicationStep,
  ApplicationSubPartStep,
  ApplicationSubStep,
  CommonResponse,
  QueryListResponse,
  Subscription,
} from '@acorn/util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@env';
import { saveAs } from 'file-saver';

import { isEmpty, omit } from 'lodash';

import { Observable } from 'rxjs';

@Injectable()
export class ApplicationService {
  #httpClient = inject(HttpClient);
  #applicationUserId = inject(APPLICATION_ID);

  createApplication(name: string, status: 'Active') {
    const params = this.#applicationUserId.value
      ? `?applicationId=${this.#applicationUserId.value}`
      : '/Current';

    return this.#httpClient.post<CommonResponse<Application>>(
      `${environment.apiUrls.customer}/api/Application${params}`,
      { name, status, latest: true }
    );
  }
  completeSubscriptionPayment(
    id: string
  ): Observable<CommonResponse<Subscription | null>> {
    return this.#httpClient.put<CommonResponse<Subscription | null>>(
      `${environment.apiUrls.customer}/api/User/CompleteSubscriptionPayment/${id}`,
      {}
    );
  }
  updateCurrentApplication(application: Partial<Application>) {
    const params = this.#applicationUserId.value
      ? `/${this.#applicationUserId.value}`
      : '/Current';

    return this.#httpClient.put<CommonResponse<string>>(
      `${environment.apiUrls.customer}/api/Application${params}`,
      application
    );
  }

  updateCurrentProgress(
    currentStep: ApplicationStep,
    currentSubStep: ApplicationSubStep,
    currentPartStep: ApplicationSubPartStep = null
  ) {
    const params = this.#applicationUserId.value
      ? `/${this.#applicationUserId.value}/Progress`
      : '/CurrentProgress';

    return this.#httpClient.put<CommonResponse<Application>>(
      `${environment.apiUrls.customer}/api/Application${params}`,
      { currentStep, currentSubStep, currentPartStep: currentPartStep }
    );
  }

  getApplications(filter: ApplicationFilter) {
    return this.#httpClient.post<QueryListResponse<Application[]>>(
      `${environment.apiUrls.customer}/api/Application/get-list`,
      { ...filter, pageNumber: filter.pageNumber + 1 }
    );
  }

  deleteApplication(id: string) {
    return this.#httpClient.delete<CommonResponse<Application>>(
      `${environment.apiUrls.customer}/api/Application`,
      { params: { id } }
    );
  }

  updateApplicationStatus(applicationId: string, status: ApplicationStatus) {
    return this.#httpClient.put<CommonResponse<Application>>(
      `${environment.apiUrls.customer}/api/Application/${applicationId}/Status`,
      { status }
    );
  }

  updateCurrentApplicationStatus(status: ApplicationStatus) {
    const params = this.#applicationUserId.value
      ? `/${this.#applicationUserId.value}/Status`
      : '//Current/Status';

    return this.#httpClient.put<CommonResponse<Application>>(
      `${environment.apiUrls.customer}/api/Application${params}`,
      { status }
    );
  }

  downloadApplication(appId: string): Observable<HttpResponse<Blob>> {
    return this.#httpClient.get<Blob>(
      `${environment.apiUrls.customer}/api/ApplicationWealthHealth/report/${appId}`,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  downloadAllData(appId: string): Observable<HttpResponse<Blob>> {
    return this.#httpClient.get<Blob>(
      `${environment.apiUrls.customer}/api/ApplicationWealthHealth/history-report/${appId}`,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  approveCustomerEdit(appId: string): Observable<CommonResponse<string>> {
    return this.#httpClient.put<CommonResponse<string>>(
      `${environment.apiUrls.customer}/api/Application/${appId}/Editable`,
      null
    );
  }

  getApplicationsRequestHandler(
    oldFilter: ApplicationFilter,
    filter: Partial<ApplicationFilter> | undefined
  ) {
    let newFilter: ApplicationFilter = Object.assign(oldFilter, filter);

    if (newFilter.status === 'None') {
      delete newFilter.status;
    }

    if (isEmpty(newFilter.sortDirection)) {
      newFilter = omit(newFilter, ['sortBy', 'sortDirection']);
    }

    return newFilter;
  }

  downloadFile(response: HttpResponse<Blob>) {
    let fileName = 'report.xlsx';
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    saveAs(response.body as Blob, fileName);
  }

  uploadPDFForApplication(
    files: FileList,
    applicationId: string,
    applicationFileType: string
  ) {
    const formData: FormData = new FormData();
    for (const file of Array.from(files)) {
      formData.append('Files', file);
    }
    formData.append('ApplicationId', applicationId);
    formData.append('ApplicationFileType', applicationFileType);

    return this.#httpClient.post<CommonResponse<any>>(
      `${environment.apiUrls.admin}/api/File/uploads`,
      formData
    );
  }

  getFileListOfApplication(applicationId: string) {
    return this.#httpClient.get<CommonResponse<any>>(
      `${environment.apiUrls.admin}/api/File/application`,
      { params: { applicationId } }
    );
  }

  downLoadPDF(url: string): Observable<HttpResponse<Blob>> {
    return this.#httpClient.get<Blob>(
      `${environment.apiUrls.admin}/api/File/download`,
      { observe: 'response', responseType: 'blob' as 'json', params: { url } }
    );
  }

  triggerWebhook(firstName:string,lastName:string,status:string,email:string): Observable<any> {
    const modal = {
      firstName:firstName,
      lastName:lastName,
      status:status,
      email:email
    }
    const webhookUrl = `${environment.apiUrls.calculation}Webhooks`;
    return this.#httpClient.post(webhookUrl , modal);
  }

  public updateStatus(applicationId: any) {
    return this.#httpClient.post<CommonResponse<any>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/update-status` ,''
    );
  }
}
