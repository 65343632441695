import { AnswerType, GoalSettingPartStep, GoalSettingStep, GoalStep } from '../enum';

export interface ApplicationGoal {
  applicationUserId: string;
  originalGoals: Goal[];
  applicationGoals: ApplicationGoals;
}

export interface Goal {
  id: string;
  content: string;
  tags: string[];
  description: string;
  order: number;
  goalCategory: GoalSettingPartStep;
}

export interface ApplicationGoals {
  [GoalStep.Round1]: string[];
  [GoalStep.Round2]: string[];
  [GoalStep.Round3]: string[];
  [GoalStep.RoundVerify]: string[];
  [GoalStep.GoalMetrics]: string[];
}

export interface GoalMetricsQuestion {
  goalId: string;
  goalName?: string;
  questions: GoalQuestion[];
  explainer: string;
  order: number;
}

export interface GoalQuestion {
  id: string;
  content: string;
  defaultValue: string;
  answerType: AnswerType;
  isNumberOfItemQuestion: boolean;
  childrenQuestions: GoalQuestion[];
  totalGroups?: unknown[];
}

export interface NextStepAction {
  applicationGoals: ApplicationGoals;
  nextGoalStep?: GoalStep;
}

export interface NextGoalStepAction {
  applicationGoals: ApplicationGoalsSteps;
  nextGoalStep?: GoalSettingStep;
}

export interface ApplicationGoalSettings {
  applicationUserId: string;
  originalGoals: Goal[];
  applicationGoals: ApplicationGoalsSteps;
}

export interface ApplicationGoalsSteps {
  [GoalSettingStep.GoalSetting]: string[];
  [GoalSettingStep.RoundVerify]: string[];
  [GoalSettingStep.GoalMetrics]: string[];
}
