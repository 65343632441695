<button
  *ngIf="backButtonEnabled"
  mat-button
  class="back-button"
  [ngClass]="customBackClass"
  [disabled]="isLoading"
  (keypress)="onBackHandler($event)"
  (click)="onBackHandler($event)"
>
  Back
</button>

<button
  *ngIf="primaryButtonEnabled"
  mat-flat-button
  color="primary"
  [disabled]="isLoading"
  [ngClass]="customNextClass"
  (click)="next.emit()"
>
  <div class="button-content-wrapper">
    <span>{{ isLoading ? 'Saving...' : primaryButtonLabel }}</span>

    <mat-spinner
      *ngIf="isLoading; else iconTemplate"
      diameter="22"
    ></mat-spinner>
  </div>
</button>

<ng-template #iconTemplate>
  <svg-icon-sprite
    [src]="'assets/sprites/sprite.svg#arrow-right'"
    [width]="'24px'"
  ></svg-icon-sprite>
</ng-template>
